<template>
  <b-form-group
    :label="label"
    :label-for="fieldName"
  >
    <b-form-input
      v-model="model"
      :name="fieldName"
      :placeholder="label"
      :state="state"
      @change="onChange"
    />
    <small
      v-if="validationContext"
      class="text-danger"
    >
      {{ validationContext.errors[0] }}
    </small>
  </b-form-group>
</template>

<script>
export default {
  name: 'ProductAttributeInput',
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    validationContext: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
    fieldName() {
      // eslint-disable-next-line no-underscore-dangle
      return `${this.label}_${this._uid}`
    },
    state() {
      return this.validationContext ? this.$getValidationState(this.validationContext) : null
    },
  },
  methods: {
    onChange(eventPayload) {
      this.$emit('change', eventPayload)
    },
  },
}
</script>

<style scoped>
</style>
